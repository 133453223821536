import { ControlState, Text } from "@/constants/const";
import { Keys } from "@/constants/key";
import { useListMetas } from "@/hooks/services/list.service";
import { num } from "@/utils/convert";
import { forEach } from "lodash";
import moment from "moment";
import { defineStore } from "pinia";
import { computed } from 'vue';

const _SERVER_META = Object.freeze({
  bidding: {
    predict: {
      dayahead: {
        plan: {
          predict: [
            // 예측량 확인되는 시간
            "08:40",
            "09:40",
            "10:40"
          ],
          submit: [
            // 제출 api를 요청하는 시간
            "08:50",
            "09:50",
            "10:50",
          ],
          prohibit: [
            // 수동제출 금지 시간 (제출 api 처리시간)
            "08:50~08:55",
            "09:50~09:55",
            "10:50~00:00"
          ],
        }
      },
      realtime: {
        plan: {
          "predict": [
            // 예측량 확인되는 시간
            20,
            35
          ],
          "submit": [
            // 제출 api를 요청하는 시간
            25,
            40
          ],
          "prohibit": [
            // 수동제출 금지 시간 (제출 api 처리시간)
            // 46분 부터는 다음 구간 제출
            "40~45"
          ],
          // 11시 구간이면 10:00~11:00 를 의미한다.
          // 마감시간은 T(구간)-75 이다.
          // 21:45분에 하루 시장을 마감 한다. 
          // (T(마지막 24시 구간, 23:00~24:00) - 75) : 23:00 - 75 = 21:45)
          // 21:46분부터는 다음날을 선택해서 입찰 진행 해야한다.
          // (T(처음 1시 구간, 00:00~01:00) - 75) : 00:00 - 75 = 전일 22:46?)

          // 마감시간
          // 01시 구간(00:00~01:00) 전일 21:46~전일 22:40
          // 02시 구간(01:00~02:00) 전일 22:46~전일 23:40
          // 03시 구간(02:00~03:00) 전일 23:46~    00:40
          // 04시 구간(03:00~04:00)     00:46~    01:40
          // 05시 구간(04:00~05:00)     01:46~    02:40
          // 06시 구간(05:00~06:00)     02:46~    03:40
          // 07시 구간(06:00~07:00)     03:46~    04:40
          // 08시 구간(07:00~08:00)     04:46~    05:40
          // 09시 구간(08:00~09:00)     05:46~    06:40
          // 10시 구간(09:00~10:00)     06:46~    07:40
          // 11시 구간(10:00~11:00)     07:46~    08:40
          // 12시 구간(11:00~12:00)     08:46~    09:40
          // 13시 구간(12:00~13:00)     09:46~    10:40
          // 14시 구간(13:00~14:00)     10:46~    11:40
          // 15시 구간(14:00~15:00)     11:46~    12:40
          // 16시 구간(15:00~16:00)     12:46~    13:40
          // 17시 구간(16:00~17:00)     13:46~    14:40
          // 18시 구간(17:00~18:00)     14:46~    15:40
          // 19시 구간(18:00~19:00)     15:46~    16:40
          // 20시 구간(19:00~20:00)     16:46~    17:40
          // 21시 구간(20:00~21:00)     17:46~    18:40
          // 22시 구간(21:00~22:00)     18:46~    19:40
          // 23시 구간(22:00~23:00)     19:46~    20:40
          // 24시 구간(23:00~24:00)     20:46~    21:40
        }
      }
    }
  }

});

export const useMetaStore = defineStore(Keys.STORE.META, () => {
  const Meta = computed(() => ({
    DAYAHEAD_BIDDING_TIME: '11:00', // HH:mm,
    DAYAHEAD_BIDDING_DEADLINE_TIME: '11:00', // HH:mm,
    DAYAHEAD_WINNING_TIME: '18:00', // HH:mm
    DAYAHEAD_EXPECTED_AUTOSUBMIT_TIMES: [
      '08:55', // 08:50~08:55
      '09:55', // 09:50~09:55
      '10:55', // 10:50~10:55
    ],
    // DAYAHEAD_EXPECTED_AUTOSUBMIT_TIMES: ServerMeta.PROHIBIT.map((e) => e.split('~')[1]),

    REALTIME_BDDING_DEADLINE_DIFF_MINUTE: '-75', // 75분 전 마감
    REALTIME_WINNING_MINUTE: '-15', // 15분 전 낙찰
    REALTIME_WINNING_TIME: '18:00', // HH:mm
    // 
    SERVER_META: _SERVER_META,
  }));

  const splitedDayaheadBiddingTime = computed(() => Meta.value.DAYAHEAD_BIDDING_TIME.split(':'));
  const dayaheadBiddingHour = computed(() => num(splitedDayaheadBiddingTime.value?.[0]) ?? 0);
  const dayaheadBiddingMinute = computed(() => num(splitedDayaheadBiddingTime.value?.[1]) ?? 0);

  const getDayaheadBiddingTime = () => {
    const m = moment();
    return m.format('HH:mm') > Meta.value.DAYAHEAD_BIDDING_TIME
      ? m.add(1, 'days').set('hours', dayaheadBiddingHour.value).set('minute', dayaheadBiddingMinute.value).set('seconds', 0).toDate().getTime()
      : m.set('hours', dayaheadBiddingHour.value).set('minute', dayaheadBiddingMinute.value).set('seconds', 0).toDate().getTime()
  };

  const splitedDayaheadBiddingDeadlineTime = computed(() => Meta.value.DAYAHEAD_BIDDING_DEADLINE_TIME.split(':'));
  const dayaheadBiddingDeadlineHour = computed(() => num(splitedDayaheadBiddingDeadlineTime.value?.[0]) ?? 0);
  const dayaheadBiddingDeadlineMinute = computed(() => num(splitedDayaheadBiddingDeadlineTime.value?.[1]) ?? 0);

  const getDayaheadBiddingDeadlineTime = () => {
    const m = moment();
    return m.format('HH:mm') > Meta.value.DAYAHEAD_BIDDING_DEADLINE_TIME
      ? m.add(1, 'days').set('hours', dayaheadBiddingDeadlineHour.value).set('minute', dayaheadBiddingDeadlineMinute.value).set('seconds', 0).toDate().getTime()
      : m.set('hours', dayaheadBiddingDeadlineHour.value).set('minute', dayaheadBiddingDeadlineMinute.value).set('seconds', 0).toDate().getTime()
  };

  const splitedDayaheadWinningTime = computed(() => Meta.value.DAYAHEAD_WINNING_TIME.split(':'));
  const dayaheadWinningHour = computed(() => num(splitedDayaheadWinningTime.value?.[0]) ?? 0);
  const dayaheadWinningMinute = computed(() => num(splitedDayaheadWinningTime.value?.[1]) ?? 0);

  const splitedRealtimeWinningTime = computed(() => Meta.value.REALTIME_WINNING_TIME.split(':'));
  const realtimeWinningHour = computed(() => num(splitedRealtimeWinningTime.value?.[0]) ?? 0);
  const realtimeWinningMinute = computed(() => num(splitedRealtimeWinningTime.value?.[1]) ?? 0);

  const splitedDayaheadExpectedAutosubmitTimeLast = computed(() => Meta.value.DAYAHEAD_EXPECTED_AUTOSUBMIT_TIMES[Meta.value.DAYAHEAD_EXPECTED_AUTOSUBMIT_TIMES.length - 1].split(':'));
  const dayaheadExpectedAutosubmitHourLast = computed(() => num(splitedDayaheadExpectedAutosubmitTimeLast.value?.[0]) ?? 0);
  const dayaheadExpectedAutosubmitMinuteLast = computed(() => num(splitedDayaheadExpectedAutosubmitTimeLast.value?.[1]) ?? 0);

  const splitedDayaheadExpectedAutosubmitTimeFirst = computed(() => Meta.value.DAYAHEAD_EXPECTED_AUTOSUBMIT_TIMES[0].split(':'));
  const dayaheadExpectedAutosubmitHourFirst = computed(() => num(splitedDayaheadExpectedAutosubmitTimeFirst.value?.[0]) ?? 0);
  const dayaheadExpectedAutosubmitMinuteFirst = computed(() => num(splitedDayaheadExpectedAutosubmitTimeFirst.value?.[1]) ?? 0);

  const _listMeta = useListMetas();

  // type ServerMeta = Record<
  //   'substationOption'
  //   | 'dlOption'
  //   | 'monitoringCompany'
  //   | 'controlCompany'
  //   | 'controlType'
  //   | 'infraType'
  //   | 'infraInstallType'
  //   | 'infraModuleType'
  //   , { id: number, name: string }[]
  // >;

  const SERVER_META = computed(() => {
    const result = {
      substationOption: _listMeta.substation.data,
      dlOption: _listMeta.dl.data,
      monitoringCompany: _listMeta.monitoring.data,
      controlCompany: _listMeta.control.data?.company,
      controlType: _listMeta.control.data?.control_type,
      ControlType: {
        [Text.SERVER__ON_OFF]: _listMeta.control.data?.control_type[0].name,
        [Text.SERVER__PERCENTAGE]: _listMeta.control.data?.control_type[1].name,
      },
      ControlState: ControlState,
      //
      infraType: _listMeta.meta.data?.['resource-type'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })), // plant_type
      infraInstallType: _listMeta.meta.data?.['install-type'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })),
      infraModuleType: _listMeta.meta.data?.['module-type'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })),
      resourceContractType: _listMeta.meta.data?.['resource-contract-type'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })).reverse(), // api에서 선택 option과 순서 반대로 주게되어있어 역순 처리 했습니다. api 순서 변경되면 현경 해주세요.
      resourceFixedContractType: _listMeta.meta.data?.['resource-fixed-contract-type'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })),
      resourceContractDate: _listMeta.meta.data?.['resource-contract-date'].map((e, idx) => ({
        id: idx, // disabled
        name: e[0],
      })),
      defaultFailedDate: _listMeta.meta.data?.["default-failed-date"],
      allowableOutputStandard: _listMeta.meta.data?.['allowable-output-standard'],
    };

    let isLoading = false;
    forEach(result, (value) => {
      isLoading = !value || isLoading;
    });

    if (isLoading) return null;

    return result;
  });

  return {
    Meta,
    dayaheadBiddingHour,
    dayaheadBiddingMinute,
    dayaheadBiddingDeadlineHour,
    dayaheadBiddingDeadlineMinute,
    dayaheadWinningHour,
    dayaheadWinningMinute,
    realtimeWinningHour,
    realtimeWinningMinute,
    getDayaheadBiddingTime,
    getDayaheadBiddingDeadlineTime,
    dayaheadExpectedAutosubmitHourLast,
    dayaheadExpectedAutosubmitMinuteLast,
    dayaheadExpectedAutosubmitHourFirst,
    dayaheadExpectedAutosubmitMinuteFirst,
    //
    SERVER_META,
  }
});