import Cookies from 'js-cookie';

export const REFRESH_TOKEN_KEY = 'haezoom-v__refresh';
export const refreshToken = {
  get() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  },
  set(refresh: string) {
    Cookies.set(REFRESH_TOKEN_KEY, refresh);
  },
  remove() {
    Cookies.remove(REFRESH_TOKEN_KEY);
  }
}