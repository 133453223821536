import { MODE } from "@/constants/setting";
import * as Sentry from '@sentry/vue';
import moment from "moment";

type LoggerOptions = {
  isDebug?: boolean,
}

class Logger {
  readonly options?: LoggerOptions;
  readonly prefix!: string;
  readonly separetor: string = ':';

  constructor(prefix: string, options?: LoggerOptions) {
    this.options = options;
    this.prefix = prefix;
  }

  get isDebug() {
    return !!this.options?.isDebug
  }

  scope(str: string) {
    return new Logger(`${this.prefix} ${this.separetor} ${str}`, this.options);
  }

  debug(...data: any[]) {
    this.isDebug && console.debug(`${this.prefix} ${this.separetor}`, ...data);
  }

  info(...data: any[]) {
    const texts = [
      `%c[${moment().format('YYYY-MM-DD HH:mm:ss')}]\%c ${this.prefix} ${this.separetor}`,
    ];
    const noTexts = [];
    const colors = [
      'color: orange;', 
      '',
    ];
    for (const d of data) {
      if (typeof d === 'string') {
        if (d.startsWith('color')) {
          colors.push(d);
        } else {
          texts.push(d);
        }
      } else {
        noTexts.push(d);
      }
    }
    console.info(texts.join(' '), ...colors, ...noTexts,);
  }

  warn(...data: any[]) {
    console.warn(`${this.prefix} ${this.separetor}`, ...data);
  }

  error(...data: any[]) {
    console.error(`${this.prefix} ${this.separetor}`, ...data);
  }

  capture(err: Error) {
    Sentry.captureException(err);
  }
}

class ProductLogger extends Logger {
  debug(...data: any[]) {
    data;
    // nop,
  }

  info(...data: any[]) {
    this.isDebug && console.info(`${this.prefix} ${this.separetor}`, ...data);
  }

  warn(...data: any[]) {
    console.warn(`${this.prefix} ${this.separetor}`, ...data);
  }
}

export default MODE === 'development' ? Logger : ProductLogger