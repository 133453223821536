import { Text } from '@/constants/const';
import { z } from 'zod';

export const SubstationResponseData = z
  .array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  )
  .min(0);

export const DlResponseData = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
  })
);

export const MonitoringResponseData = z.tuple([
  z.object({
    id: z.literal(1),
    name: z.literal(Text.SERVER__LASEE),
  }),
]);

export const ControlResponseData = z.object({
  company: z.tuple([
    z.object({
      id: z.literal(1),
      name: z.literal(Text.SERVER__LASEE),
    }),
    z.object({
      id: z.literal(2),
      name: z.literal(Text.SERVER__G2POWER),
    }),
  ]),
  control_type: z.tuple([
    z.object({
      id: z.literal(1),
      name: z.literal(Text.SERVER__ON_OFF),
    }),
    z.object({
      id: z.literal(2),
      name: z.literal(Text.SERVER__PERCENTAGE),
    }),
  ]),
});

export const MetaResponseData = z.object({
  'resource-type': z.tuple([z.tuple([z.literal(Text.SERVER__SOLAR), z.literal('Solar')]), z.tuple([z.literal(Text.SERVER__WIND), z.literal('Wind')])]),
  'resource-variable': z.tuple([
    z.tuple([z.literal('정산비율'), z.literal('Billing Rate')]),
    z.tuple([z.literal('정산보상단가'), z.literal('Control Compensation Unit Price')]),
    z.tuple([z.literal('제어 on/off'), z.literal('Control On Off')]),
    z.tuple([z.literal('장기고정 계약 단가'), z.literal('Fixed Contract Price')]),
    z.tuple([z.literal('최소 지급 보장율'), z.literal('Minimum Payment Guaranteee Rate')]),
    z.tuple([z.literal('보장금액'), z.literal('Guarantee Price')]),
  ]),
  'group-status': z.tuple([
    z.tuple([
      // z.literal("운영중"),
      z.literal(1),
      z.literal('Operating'),
    ]),
    z.tuple([
      // z.literal("테스트중"),
      z.literal(2),
      z.literal('Testing'),
    ]),
    z.tuple([
      // z.literal("심사중"),
      z.literal(3),
      z.literal('Under Review'),
    ]),
    z.tuple([
      // z.literal("대기"),
      z.literal(4),
      z.literal('Wait'),
    ]),
  ]),
  'group-grid': z.tuple([z.tuple([z.literal('육지'), z.literal('Land')]), z.tuple([z.literal('제주'), z.literal('Jeju')])]),
  'install-type': z.tuple([
    z.tuple([z.literal(Text['SERVER__토지']), z.literal('Land')]),
    z.tuple([z.literal(Text['SERVER__임야(산)']), z.literal('Forest')]),
    z.tuple([z.literal(Text['SERVER__건물']), z.literal('Building')]),
    z.tuple([z.literal(Text['SERVER__고속도로유휴부지']), z.literal('Highway')]),
    z.tuple([z.literal(Text['SERVER__수상']), z.literal('Water')]),
    z.tuple([z.literal(Text['SERVER__기타']), z.literal('Etc')]),
  ]),
  'module-type': z.tuple([
    z.tuple([z.literal(Text['SERVER__고정형']), z.literal('Fixed')]),
    z.tuple([z.literal(Text['SERVER__가변형']), z.literal('Variable')]),
    z.tuple([z.literal(Text['SERVER__기타']), z.literal('Etc')]),
  ]),
  'resource-contract-type': z.tuple([
    z.tuple([z.literal(Text['SERVER__현물고객']), z.literal('Spot')]),
    z.tuple([z.literal(Text['SERVER__장기고정고객']), z.literal('Fixed')]),
  ]),
  'resource-fixed-contract-type': z.tuple([
    z.tuple([z.literal(Text['SERVER__SMP_add_REC']), z.literal('Smp Rec')]),
    z.tuple([z.literal(Text['SERVER__SMP_add_REC_mul_WEIGHT']), z.literal('Smp Rec Weight')]),
  ]),
  'resource-contract-date': z.tuple([
    z.tuple([z.literal(Text['SERVER__SETTLE_202406']), z.literal('Settle 202406')]),
    z.tuple([z.literal(Text['SERVER__SETTLE_202409']), z.literal('Settle 202409')]),
    z.tuple([z.literal(Text['SERVER__SETTLE_202503']), z.literal('Settle 202503')]),
    z.tuple([z.literal(Text['SERVER__SETTLE_202509']), z.literal('Settle 202509')]),
    z.tuple([z.literal(Text['SERVER__SETTLE_202603']), z.literal('Settle 202603')]),
    z.tuple([z.literal(Text['SERVER__SETTLE_202609']), z.literal('Settle 202609')]),
  ]),
  'default-failed-date': z.number(), // [TAMNA-1657] meter 데이터 마지막 가져오는 시간 설정 (마지막 데이터가 기준시간보다 전에 있는 경우 데이터 가져올수 없음)
  'allowable-output-standard': z.number(), // [TAMNA-1680] 허용출력값 서버에서 관리
});

export const GroupDispatchOrderHistory = z.record(z.string(), z.record(z.string(), z.number()));