export const zIndexMap = {
  TABLE_POPUP: 2,
  POP_CONFIRM: 201,
  GNB_ALERT: 1049,
  DROPDOWN: 1050,
  HEADER: 1051,
  GNB_MENU: 1052,
  GNB_SCROLLBAR: 1052,
  MODAL: 1053,
  PWA_POPUP: 1054,
  NOTIFICATION: 1099,
  MESSAGE: 3000,
  GNB_SCROLLBAR_TRACK: 9999,
  POP_OVER: 99999,
} as const;
