import { AlertType } from "@/constants/const";
import { z } from "zod";

export const AlertCard = z.object({
  // type: z.string(),
  type: z.enum([AlertType.NONE, AlertType.INSTRUCTION, AlertType.LIMITATION]),
  start: z.string(),
  end: z.string(),
  hour: z.number(),
  section: z.number(),
  target: z.number().nullable(),
  is_control: z.boolean(),
  avg_power: z.number(),
  winning: z.number().nullable(),
  dispatch_order_target: z.number().nullable(),
  disabled: z.boolean(),
  created_at: z.string(),
});
  
export const AlertCardList = z.array(AlertCard);

export const AlertExist = z.object({
  message: z.string(),
  result: z.boolean(),
});