<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const onClickBackBtn = () => {
  const redirect = (route.query.redirect || '/') as string;

  router.push({
    path: redirect,
    replace: true,
  })
};
</script>

<template>
  <a-space direction="vertical">
    <a-result 
      title="Connection Error"
    >
      <template #extra>
          <a-typography-text type="secondary">
            <a-icon type="info-circle" /> 
            실시간 데이터 연결 오류 10회 이상 발생하였습니다.
          </a-typography-text>
          <br />
          <a-typography-text type="secondary">
            <a-icon type="info-circle" /> 
            사이트 성능을 위해 페이지를 이동하였습니다.
          </a-typography-text>
          <br />
          <a-typography-text type="secondary">
            <a-icon type="info-circle" /> 
            원래 페이지로 돌아가시려면 아래 버튼을 클릭해 주세요.
          </a-typography-text>
          <br />
          <br />
          <a-button type="primary" class="text-center ma-2" @click="onClickBackBtn">
            <!-- <HomeOutlined /> -->
            돌아가기
          </a-button>
        </template>
    </a-result>
  </a-space>
</template>