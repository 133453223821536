import { BffApis } from '@/constants/api';
import { useRequestStore } from '@/store/useRequestStore';


export const startEffect = (handler: Function, ) => {
  const { bff } = useRequestStore();

  return bff.$stream(BffApis.EFFECT, (evt) => {
    if (evt.data) {
      handler(evt.data);
    }
  });
}