import { BffApis, IdLiteral } from '@/constants/api';

import { wrapQueryPromise } from '@/utils/api';
import { useRequestStore } from '@/store/useRequestStore';

import { AlertCardList, AlertExist } from '@/schema/api/bff/alert-cards.schema';

export const getGroupAlertCards = async (groupId: string) => {
  const { bff } = useRequestStore();
  return wrapQueryPromise(
    bff.$get(BffApis.ALERT_CARDS__GROUP__$1.replace(IdLiteral.GROUP_ID, groupId)),
    AlertCardList,
  );
}

export const isExistAlertCard = async () => {
  const { bff } = useRequestStore();
  return wrapQueryPromise(bff.$get(BffApis.ALERT_CARDS__EXIST), AlertExist)
    .then((data) => {
      return data.result;
    });
}