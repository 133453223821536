import { ResourceType, Text } from '@/constants/const';
import { t } from '@/constants/text';
import { num } from '@/utils/convert';
import { wrapDjangoPaginationList } from '@/utils/scheme';
import z from 'zod';
//
export const KpxIdentifier = z.object({
  id: z.number(),
  kpx_cbp_gen_id: z.string(),
});

export const Ess = z.object({
  id: z.number(),
  kpx_identifier: KpxIdentifier.nullable(),
  ess_cap: z.number(), // *
  ess_soc_l_lim: z.number(), // *
  ess_soc_h_lim: z.number(), // *
  pcs_cap: z.number(), // *
  pcs_h_lim: z.number().nullable(),
  ch_start: z.number().min(0).max(23), // *
  ch_end: z.number().min(0).max(23), // *
  dch_start: z.number().min(0).max(23), // *
  dch_end: z.number().min(0).max(23), // *
  ess_loss_eff: z.number().nullable(),
  ess_reserve: z.number().nullable(),
  ch_min_cap: z.number().nullable(),
  ch_max_cap: z.number().nullable(),
  dch_min_cap: z.number().nullable(),
  dch_max_cap: z.number().nullable(),
  // ess_mfr: z.number().or(z.string()).nullable(),
  // pcs_mfr: z.number().or(z.string()).nullable(),
  ess_mfr: z.string().nullable(),
  pcs_mfr: z.string().nullable(),
  out_ctrl_feat: z.string().nullable(),
});

export const Inverter = z.object({
  id: z.number(),
  capacity: z.number(),
  tilt: z.number(),
  azimuth: z.number(),
  install_type: z.string().nullable(),
  module_type: z.string().nullable(),
});

export const ContractDate = z.union([
  z.literal(Text['SERVER__SETTLE_202406']),
  z.literal(Text['SERVER__SETTLE_202409']),
  z.literal(Text['SERVER__SETTLE_202503']),
  z.literal(Text['SERVER__SETTLE_202509']),
  z.literal(Text['SERVER__SETTLE_202603']),
  z.literal(Text['SERVER__SETTLE_202609']),
]);

export const Resource = z.object({
  id: z.number(),
  modified_at: z.string().refine((value) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
  }),
  infra: z.object({
    id: z.number(),
    name: z.string(),
    type: z.union([z.literal(t(ResourceType.SOLAR)), z.literal(t(ResourceType.WIND))]),
    address: z.string(),
    latitude: z.number(),
    longitude: z.number(),
    altitude: z.number().nullable(),
    capacity: z.number().nullable(),
    carrier_fk: z.number().nullable(), // ??
    install_date: z
      .string()
      .regex(/^(?:19|20)\d\d-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]))$/)
      .nullable(),
    kpx_identifier: KpxIdentifier.nullable(),
    // inverter: z.array(Inverter).nonempty(),
    inverter: z.array(Inverter).min(0),
    ess: z.array(Ess).optional(),
  }),
  monitoring: z
    .object({
      id: z.number(),
      company: z.number(),
      rtu_id: z.string(),
      resource: z.number(), // 참조 resource id 인듯
    })
    .nullable(),
  control: z
    .array(
      z.object({
        id: z.number(),
        company: z.number(),
        control_type: z.number(),
        controllable_capacity: z.number(),
        rtu_id: z.string(),
        priority: z.number(),
        resource: z.number(), // 참조 resource id 인듯
        onoff_inverter_capacity: z.record(z.string(), z.string()).nullable(), // { "1": 10 }
      })
    )
    .nullable(),
  substation: z.number().nullable(),
  dl: z.number().nullable(),
  fixed_contract_price: z.number().nullable(),
  guaranteed_capacity: z.number().nullable(),
  contract: z.object({
    id: z.number(),
    modified_at: z.string().nullable(), // "2024-08-18T16:37:34.558189"
    resource: z.number(),
    contract_type: z.union([z.literal(Text['SERVER__장기고정고객']), z.literal(Text['SERVER__현물고객'])]), // "현물고객"
    weight: z.number(),
    contract_date: ContractDate,
    fixed_contract_type: z.union([z.literal(Text['SERVER__SMP_add_REC']), z.literal(Text['SERVER__SMP_add_REC_mul_WEIGHT'])]).nullable(), // 현물고객이면 null
    fixed_contract_price: z.number().nullable(), // 현물고객이면 null
    fixed_contract_agreement_date: z.string().nullable(), // 현물고객이면 null
  }).nullish(),
});

export const GroupAssigned = z.array(
  z.object({
    applied_at: z.string().regex(/^(?:19|20)\d\d-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]))$/),
    group_id: z.number().nullable(),
    resource_id: z.number(),
    memo: z.string(),
  })
);

export const ResourcesResponseData = wrapDjangoPaginationList(z.array(Resource));
export const ResourceResponseData = Resource;

export const ResourcesInGroupResponseData = z.array(Resource);

export const ResourcesParticipatingCapacityRequestData = z.object({
  variable_type: z.literal('참여 설비용량'),
  start_date: z.string().regex(/^(19\d{2}|20[0-4]\d|2050)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/), // "2023-11-24",
  start_time: z.number().min(1).max(24),
  end_date: z.string().regex(/^(19\d{2}|20[0-4]\d|2050)-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/), // "2023-11-24",
  end_time: z.number().min(1).max(24),
  applied_value: z.string().regex(/^\d+(\.[\d]{1,2})?$/), //"100",
  memo: z.string().regex(/^.+$/),
});

export const ResourceTotalParticipatingCapacityResoponseData = z.array(
  z.object({
    id: z.number(),
    r_id: z.number(), // 147,
    name: z.string(), // "고산",
    applied_value: z.string(), // "200",
    capacity: z.number(), // 97.5,
    start_date: z.string().regex(/^(1900-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])|[1-9][0-9]{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]))$/), // "2023-10-10",
    start_time: z.number().min(1).max(24),
    end_date: z.string().regex(/^(1900-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])|[1-9][0-9]{3}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]))$/), // "2023-10-15",
    end_time: z.number().min(1).max(24),
    memo: z.string(), // "변경 사유",
    // modifier: z.string().nullable(), // "2023-10-23T13:08:11.855508",
    // modified_at: z.string(), // "2023-10-23T13:08:11.855508",
    modifier: z.array(z.tuple([z.string(), z.string()])), // name, date
    variable_type: z.literal('참여 설비용량'), // "참여 설비용량"
  })
);

export const GroupTotalResponseData = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    kpx_code: z.string(),
    grid: z.union([z.literal('제주'), z.literal('육지')]),
  })
);

export const GroupAssignResponseData = GroupAssigned;


export const GroupStatusResponseData = wrapDjangoPaginationList(z.array(z.object({
  applied_at: z.string(),
  group_id: z.number(),
  status: z.number(),
})));

export const ResourceErrorsResponseData = z.record(
  z.string(),
  z.object({
    err_rate_30d: z.number().nullable(),
  })
);

const requiredFloat = () => /^[-+]?\d+(\.\d+)?$/;
const float = () => /^([-+]?\d+(\.\d+)?)|()$/;

export const ResourceRegistRequestData = z.object({
  infra: z.object({
    id: z.number().nullish(),
    name: z.string().min(1),
    kpx_identifier: z.object({
      kpx_cbp_gen_id: z.string().min(1),
    }),
    type: z.string().min(1), // sloar? 태양광? Meta api가 안됨...
    address: z.string().min(1),
    latitude: z.string().regex(requiredFloat(), 'not float'), // 33.4376439360935,
    longitude: z.string().regex(requiredFloat(), 'not float'), // 126.404083736918,
    altitude: z.string().nullish(), // 고도?
    capacity: z.string().nullish(), // 여기서 입력 안함
    install_date: z.string().nullish(), // 설치일?

    inverter: z
      .array(
        z.object({
          id: z.number().nullish(),
          capacity: z.string().regex(requiredFloat(), 'not float'),
          azimuth: z.string().regex(requiredFloat(), 'not float'), // 풍력일 경우
          tilt: z.string().regex(requiredFloat(), 'not float'), // 풍력일 경우
          install_type: z.string().nullish(), // 설치유형
          module_type: z.string().nullish(), // 모듈구조
        })
      )
      .min(1),
    ess: z
      .array(
        z.object({
          id: z.number().nullish(),
          kpx_identifier: z.object({
            kpx_cbp_gen_id: z.string().regex(/^.+$/),
          }),
          ess_cap: z.string().regex(requiredFloat(), 'not float'),
          ess_mfr: z.string(),
          dch_start: z.string().min(1),
          dch_end: z.string().min(1),
          dch_min_cap: z.string().min(1).regex(float(), 'not float'),
          dch_max_cap: z.string().min(1).regex(float(), 'not float'),
          ess_soc_l_lim: z.string().min(1),
          ess_soc_h_lim: z.string().min(1),
          pcs_cap: z.string().regex(requiredFloat(), 'not float'),
          pcs_mfr: z.string(),
          ch_start: z.string().min(1),
          ch_end: z.string().min(1),
          ch_min_cap: z.string().min(1).regex(float(), 'not float'),
          ch_max_cap: z.string().min(1).regex(float(), 'not float'),
          pcs_h_lim: z.string().nullish(), // 사용안함? nullish?
          out_ctrl_feat: z.string(),
        })
      )
      .min(0),
  }),
  substation: z.string().min(1), // 변전소
  dl: z.string().min(1), // D/L

  control: z
    .array(
      z.object({
        id: z.number().nullish(),
        company: z.string().min(1),
        rtu_id: z.string().min(1),
        control_type: z.string().min(1),
        controllable_capacity: z.string().regex(/^([-+]?\d+(\.\d+)?)|()$/, 'not float').nullable(), // [지투파워] ON/OFF
        onoff_inverter_capacity: z.record(z.string(), z.string()), // { "1": 10 }
      })
    )
    .min(0),
  monitoring: z.object({
    id: z.number().nullish(),
    company: z.string().min(1),
    rtu_id: z.string().min(1),
  }),
  fixed_contract_price: z.number().nullable(),
  contract: z.object({
    id: z.number().nullable(),
    weight: z.number(), // number, // 가중치
    contract_date: z.union([
      z.literal(Text['SERVER__SETTLE_202406']),
      z.literal(Text['SERVER__SETTLE_202409']),
      z.literal(Text['SERVER__SETTLE_202503']),
      z.literal(Text['SERVER__SETTLE_202509']),
      z.literal(Text['SERVER__SETTLE_202603']),
      z.literal(Text['SERVER__SETTLE_202609']),
    ]),
    //  
    contract_type: z.union([z.literal('현물고객'), z.literal('장기고정고객')]), // '현물고객' | '장기고정고객', // 계약 형태
    fixed_contract_type: z.union([z.literal('SMP+REC'), z.literal('SMP+REC*weight')]).nullable(), // 'SMP+REC' | 'SMP+REC*weight' | null, // 장기고정 계약 형태
    fixed_contract_price: z.number().nullable(), // number | null, // 장기고정 계약 단가  
    fixed_contract_agreement_date: z.string().nullable(), // string | null, // 장기고정 계약 체결일 (YYYY-MM-DD)
  }),
});

export const GroupAssginRequestData = z.object({
  group_id: z
    .string()
    .min(1)
    .transform((v) => (v === '-1' ? null : num(v))),
  resources: z.array(z.number()).min(1),
  applied_at: z.string().min(1),
  memo: z.string().min(1),
});

export const GenerationState15m = z
  .array(
    z
      .array(
        z.object({
          dayahead_scheduled_meter: z.number().nullable(), // 하루전 낙찰량
          realtime_scheduled_meter: z.number().nullable(), // 실시간 낙찰량
          accumulate_meter_15m: z.number().nullable(), // 계량값
          err_rate_15m: z.number().nullable(), // 오차율
        })
      )
      .length(4)
  )
  .length(24);

export const GenerationState1h = z
  .array(
    z.object({
      dayahead_scheduled_meter: z.number().nullable(), // 하루전 낙찰량
      realtime_scheduled_meter: z.number().nullable(), // 실시간 낙찰량
      accumulate_meter_1h: z.number().nullable(), // 라씨 계량값
      err_rate_1h: z.number().nullable(), // 오차율
      avg_set_point_1h: z.number().nullable(), // 1시간 평균 set_point
      kpx_accumulate_meter_1h: z.number().nullable(), // 1시간 KPX 계량값
      gen_time: z.number().nullable(), // 발전시간 (발전한 시간) 3.017 -> 3.017시간
    })
  )
  .length(24);

export const ResourceMeterLastInfoResponseData = z.object({
  pulse_t: z.string().nullish(), // "2024-03-13T14:01:00+09:00",
  pg: z.number().nullish(), // 6.68,
  tpg: z.number().nullish(), // 15025.88,
  inverter_t: z.string().nullish(), // "2024-03-13T14:00:00+09:00",
  p: z.number().nullish(), // 415100.0,
  m: z.number().nullish(), // 2033698000.0,
  mp: z.number().nullish(), // 495.0,
  power_ratio: z.number().nullish(), // 100.0
});
