import { Apis, IdLiteral } from '@/constants/api';
import {
  GroupAssginRequestData,
  GroupAssignResponseData,
  GroupTotalResponseData,
  ResourceRegistRequestData,
  ResourceResponseData,
  ResourcesInGroupResponseData,
  ResourcesParticipatingCapacityRequestData,
  ResourcesResponseData,
  ResourceTotalParticipatingCapacityResoponseData,
  GenerationState1h,
  GenerationState15m,
  ResourceErrorsResponseData,
  GroupStatusResponseData,
} from '@/schema/api/resource.schema';

import { useRequestStore } from '@/store/useRequestStore';
import { wrapMutationPromise, wrapQueryPromise } from '@/utils/api';
import { SchemaType } from '@/utils/types';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { Ref, computed } from 'vue';

export const useResourceList = () => {
  const queryKey = computed(() => [Apis.RESOURCES]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      return wrapQueryPromise($get(Apis.RESOURCES), ResourcesResponseData);
    },
  });
};

export const useResource = (id: Ref<string | null>) => {
  const queryKey = computed(() => [Apis.RESOURCES__$1, id.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (id.value === null) return Promise.reject(null);
      return wrapQueryPromise($get(Apis.RESOURCES__$1.replace(IdLiteral.RESOURCE_ID, id.value)), ResourceResponseData);
    },
  });
};

export const useResourceListInGroup = (groupId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__$1__RESOURCE, groupId.value, date.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (groupId.value === null) return Promise.reject(null);
      return wrapQueryPromise(
        $get(Apis.GROUP__$1__RESOURCE.replace(IdLiteral.GROUP_ID, groupId.value), {
          params: {
            date: date.value,
          },
        }),
        ResourcesInGroupResponseData
      );
    },
  });
};

export const useCreateResourceMutation = (options?: { onSuccess?: () => void; onError?: (err: Error) => void }) => {
  const { $post } = useRequestStore();
  const m = useMutation({
    mutationFn(data: SchemaType<typeof ResourceRegistRequestData>) {
      return wrapMutationPromise($post(Apis.RESOURCES, data));
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

export const useModifyResourceMutation = (
  resourceId: Ref<string | null>,
  options?: {
    onSuccess?: () => void;
    onError?: (err: Error) => void;
  }
) => {
  const { $put, $delete } = useRequestStore();
  const m = useMutation({
    // ess, control, inverter는 따로 delete 요청해서 삭제해야 한다.
    mutationFn(data: {
      payload: SchemaType<typeof ResourceRegistRequestData>;
      deleteList: {
        inverter: number[];
        ess: number[];
        control: number[];
      };
    }) {
      if (!resourceId.value) return Promise.reject(null);

      const delPromAll = [
        ...data.deleteList.inverter.map((idx) =>
          wrapMutationPromise($delete(Apis.RESOURCE__$1__INVERTER__$2.replace(IdLiteral.RESOURCE_ID, resourceId.value!).replace(IdLiteral.INVERTER_ID, `${idx}`)))
        ),
        ...data.deleteList.ess.map((idx) =>
          wrapMutationPromise($delete(Apis.RESOURCE__$1__ESS__$2.replace(IdLiteral.RESOURCE_ID, resourceId.value!).replace(IdLiteral.ESS_ID, `${idx}`)))
        ),
        ...data.deleteList.control.map((idx) =>
          wrapMutationPromise($delete(Apis.RESOURCE__$1__CONTROL__$2.replace(IdLiteral.RESOURCE_ID, resourceId.value!).replace(IdLiteral.CONTROL_ID, `${idx}`)))
        ),
      ];

      return Promise.all([...delPromAll, wrapMutationPromise($put(Apis.RESOURCES__$1.replace(IdLiteral.RESOURCE_ID, resourceId.value), data.payload))]);
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

/**
 *
 */
export const useResourceTotalParticipatingCapacity = (date: Ref<string>) => {
  const queryKey = computed(() => [Apis.RESOURCE__TOTAL_PARTICIPATING_CAPACITY, date.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      return wrapQueryPromise(
        $get(Apis.RESOURCE__TOTAL_PARTICIPATING_CAPACITY, {
          params: {
            // end_date__gte: date.value,
          },
        }),
        ResourceTotalParticipatingCapacityResoponseData
      );
    },
  });
};

export const useResourceParticipatingCapacity = (resourceId: Ref<string>) => {
  const { $get } = useRequestStore();
  return useQuery({
    queryKey: [Apis.RESOURCE__PARTICIPATING_CAPACITY, resourceId.value],
    queryFn: () => {
      if (resourceId.value === '-1') return Promise.reject(null);
      return wrapQueryPromise($get(Apis.RESOURCE__PARTICIPATING_CAPACITY.replace(IdLiteral.RESOURCE_ID, resourceId.value)), ResourceTotalParticipatingCapacityResoponseData);
    },
  });
};

export const useCreateParticipatingCapacityMutation = (
  resourceId: Ref<string>,
  options?: {
    onSuccess?: () => void;
    onError?: (err: Error) => void;
  }
) => {
  const { $post } = useRequestStore();
  const m = useMutation({
    mutationFn(data: SchemaType<typeof ResourcesParticipatingCapacityRequestData>) {
      return wrapMutationPromise($post(Apis.RESOURCE__PARTICIPATING_CAPACITY.replace(IdLiteral.RESOURCE_ID, resourceId.value), data));
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

export const useModifyParticipatingCapacityMutation = (
  id: Ref<string>,
  resourceId: Ref<string>,
  options?: {
    onSuccess?: () => void;
    onError?: (err: Error) => void;
  }
) => {
  const { $put } = useRequestStore();
  const m = useMutation({
    mutationFn(data: SchemaType<typeof ResourcesParticipatingCapacityRequestData>) {
      return wrapMutationPromise(
        $put(Apis.RESOURCE__PARTICIPATING_CAPACITY_DETAIL.replace(IdLiteral.RESOURCE_ID, resourceId.value).replace(IdLiteral.VARIABLE_ID, id.value), data)
      );
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

export const useDeleteParticipatingCapacityMutation = (
  id: Ref<string>,
  resourceId: Ref<string>,
  options?: {
    onSuccess?: () => void;
    onError?: (err: Error) => void;
  }
) => {
  const { $delete } = useRequestStore();
  const m = useMutation({
    mutationFn() {
      return wrapMutationPromise($delete(Apis.RESOURCE__PARTICIPATING_CAPACITY_DETAIL.replace(IdLiteral.RESOURCE_ID, resourceId.value).replace(IdLiteral.VARIABLE_ID, id.value)));
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

export const useGroupList = () => {
  const queryKey = computed(() => [Apis.GROUP__TOTAL]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      return wrapQueryPromise($get(Apis.GROUP__TOTAL), GroupTotalResponseData);
    },
  });
};

export const useGroupStatusList = (date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__STATUS, date.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      return wrapQueryPromise(
        $get(Apis.GROUP__STATUS, {
          params: {
            date: date.value,
          },
        }),
        GroupStatusResponseData,
      ).then((res) => res.results);
    },
  });
};

export const useGroupAssign = (date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__ASSIGN, date.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      return wrapQueryPromise(
        $get(Apis.GROUP__ASSIGN, {
          params: {
            date: date.value,
          },
        }),
        GroupAssignResponseData
      );
    },
  });
};

export const useGroupAssignMutation = (options?: { onSuccess?: () => void; onError?: (err: Error) => void }) => {
  const { $post } = useRequestStore();
  const m = useMutation({
    mutationFn(data: SchemaType<typeof GroupAssginRequestData>) {
      return wrapMutationPromise($post(Apis.GROUP__ASSIGN, data));
    },
    onSuccess: options?.onSuccess,
    onError: (err) => {
      options?.onError && options.onError(err);
      m.reset();
    },
  });

  return m;
};

/**
 * 자원에 대한 평균 오차율
 */
export const useResourceErrorsByGroupId = (groupId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__$1__RESOURCE__ERR_RATE, groupId.value, date.value]);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (groupId.value === null) return Promise.reject(null);

      return wrapQueryPromise(
        $get(Apis.GROUP__$1__RESOURCE__ERR_RATE.replace(IdLiteral.GROUP_ID, groupId.value), {
          params: {
            bid_at: date.value,
          },
        }),
        ResourceErrorsResponseData
      );
    },
  });
};

/**
 * monitorings...
 */
export const useGroupGenerationState1h = (groupId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__$1__GENERATION_STATE, groupId.value, date.value, '1h']);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (groupId.value === null) return Promise.reject(null);
      if (date.value === null) return Promise.reject(null);

      return wrapQueryPromise(
        $get(Apis.GROUP__$1__GENERATION_STATE.replace(IdLiteral.GROUP_ID, groupId.value), {
          params: {
            bid_at: date.value,
            resolution: '1h',
          },
        }),
        GenerationState1h
      );
    },
  });
};
export const useGroupGenerationState15m = (groupId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.GROUP__$1__GENERATION_STATE, groupId.value, date.value, '15m']);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (groupId.value === null) return Promise.reject(null);
      if (date.value === null) return Promise.reject(null);

      return wrapQueryPromise(
        $get(Apis.GROUP__$1__GENERATION_STATE.replace(IdLiteral.GROUP_ID, groupId.value), {
          params: {
            bid_at: date.value,
            resolution: '15m',
          },
        }),
        GenerationState15m
      );
    },
  });
};

export const useResourceGenerationState1h = (resourceId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.RESOURCE__$1__GENERATION_STATE, resourceId.value, date.value, '1h']);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (resourceId.value === null) return Promise.reject(null);
      if (date.value === null) return Promise.reject(null);

      return wrapQueryPromise(
        $get(Apis.RESOURCE__$1__GENERATION_STATE.replace(IdLiteral.RESOURCE_ID, resourceId.value), {
          params: {
            bid_at: date.value,
            resolution: '1h',
          },
        }),
        GenerationState1h
      );
    },
    staleTime: Infinity,
  });
};

export const useResourceGenerationState15m = (resourceId: Ref<string | null>, date: Ref<string>) => {
  const queryKey = computed(() => [Apis.RESOURCE__$1__GENERATION_STATE, resourceId.value, date.value, '15m']);
  const { $get } = useRequestStore();
  return useQuery({
    queryKey,
    queryFn: () => {
      if (resourceId.value === null) return Promise.reject(null);
      if (date.value === null) return Promise.reject(null);

      return wrapQueryPromise(
        $get(Apis.RESOURCE__$1__GENERATION_STATE.replace(IdLiteral.GROUP_ID, resourceId.value), {
          params: {
            bid_at: date.value,
            resolution: '15m',
          },
        }),
        GenerationState15m
      );
    },
  });
};