import { computed, ref } from 'vue';
import axios, { CreateAxiosDefaults, ResponseType } from 'axios';
import { useInterval } from '@/hooks/useInterval';

import { refresh$ } from '@/hooks/useToken';
import { defineStore } from 'pinia';
import { Keys } from '@/constants/key';
import decode from 'jwt-decode';

import moment from 'moment';
import { handleErrorMessage, stream, throwUnknownErrorMessage, toData, toScheme } from '@/utils/api';
import { RefreshResponseData } from '@/schema/api/user.schema';
import { Apis } from '@/constants/api';
import Logger from '@/utils/classes/Logger';
import { API_URL, BFF_API_KEY, BFF_API_URL, COMMON_API_URL } from '@/constants/setting';
import Cookies from 'js-cookie';
import { refreshToken } from '@/utils/cookie';

const logger = new Logger('useReqeustStore', {
  isDebug: false,
});

const defaultOptions: CreateAxiosDefaults = {
  baseURL: API_URL,
};

const defaultBffOptions: CreateAxiosDefaults = {
  baseURL: BFF_API_URL,
};

const noauthapi = axios.create(defaultOptions);

export const useRequestStore = defineStore(Keys.STORE.REQUEST, () => {
  const accessToken = ref<string | null>(null);
  const loadingAccess = ref<boolean>(false);

  const $commonapi = computed(() =>
    axios.create({
      baseURL: COMMON_API_URL,
    })
  );

  const $noauthapi = computed(() => noauthapi);
  const $api = computed(() => {
    const instance = axios.create(defaultOptions);

    instance.interceptors.request.use(async (request) => {
      logger.debug('request', request);
      while (loadingAccess.value) {
        await new Promise((resolve) => setTimeout(() => resolve(true), 300));
      }

      if (accessToken.value !== null) {
        request.headers.Authorization = `Bearer ${accessToken.value}`;
        return request;
      } else {
        const source = axios.CancelToken.source();
        request.cancelToken = source.token;
        source.cancel();
      }
      return request;
    });

    return instance;
  });

  const $get = <T, P>(
    path: string,
    config?: {
      params?: P;
      responseType?: ResponseType;
      signal?: AbortSignal;
    }
  ) => {
    return $api.value.get<T>(path, {
      params: config?.params,
      responseType: config?.responseType,
      signal: config?.signal,
    });
  };
  const $post = <T, D>(path: string, data: D) => {
    return $api.value.post<T>(path, data);
  };
  const $put = <T, D>(path: string, data: D) => {
    return $api.value.put<T>(path, data);
  };
  const $patch = <T, D>(path: string, data: D) => {
    return $api.value.patch<T>(path, data);
  };
  const $delete = (path: string) => {
    return $api.value.delete(path);
  };


  const bff = (() => {
    const $api = computed(() => {
      const instance = axios.create(defaultBffOptions);
  
      instance.interceptors.request.use(async (request) => {
        while (loadingAccess.value) {
          await new Promise((resolve) => setTimeout(() => resolve(true), 300));
        }

        request.headers['X-API-KEY'] = BFF_API_KEY;
  
        if (accessToken.value !== null) {
          request.headers.Authorization = `Bearer ${accessToken.value}`;
          return request;
        } else {
          const source = axios.CancelToken.source();
          request.cancelToken = source.token;
          source.cancel();
        }
        return request;
      });
  
      return instance;
    });

    const $stream = (path: string, handler: (event: MessageEvent) => void) => {
      const close = stream({
        url: $api.value.defaults.baseURL + path,
        headers: {
          Authorization: 'Bearer ' + accessToken.value,
          'X-Api-Key': BFF_API_KEY,
        },
        handler,
      });

      return close;
    };

    const $get = <T, P>(
      path: string,
      config?: {
        params?: P;
        responseType?: ResponseType;
        signal?: AbortSignal;
      }
    ) => {
      return $api.value.get<T>(path, {
        params: config?.params,
        responseType: config?.responseType,
        signal: config?.signal,
      });
    };
    const $post = <T, D>(path: string, data: D) => {
      return $api.value.post<T>(path, data);
    };
    const $put = <T, D>(path: string, data: D) => {
      return $api.value.put<T>(path, data);
    };
    const $patch = <T, D>(path: string, data: D) => {
      return $api.value.patch<T>(path, data);
    };
    const $delete = (path: string) => {
      return $api.value.delete(path);
    };

    return {
      $api,
      $stream,
      $get,
      $post,
      $put,
      $patch,
      $delete,
    }
  })();

  const setAccessToken = (access: string) => {
    accessToken.value = access;
    Cookies.set('X-Authorization', access, {
      domain: '.vpphaezoom.com',
    });
    const payload = decode<{ exp: number }>(access);
    const diff = moment(payload.exp * 1000).diff(moment()) - 1000 * 60; // 만료 1분전 갱신
    setTimeout(() => {
      refreshAccess();
    }, diff);
  };

  const refreshAccess = () => {
    loadingAccess.value = true;
    const refresh = refreshToken.get();
    if (refresh) {
      noauthapi
        .post(Apis.USER__TOKEN__REFRESH, {
          refresh,
        })
        .then(toData)
        .then(toScheme(RefreshResponseData))
        .then((data) => {
          setAccessToken(data.access);
          return data;
        })
        .catch(handleErrorMessage)
        .catch(throwUnknownErrorMessage)
        .finally(() => {
          loadingAccess.value = false;
        });
    } else {
      loadingAccess.value = false;
    }
  };

  refreshAccess();

  useInterval(() => {
    const refresh = refreshToken.get();
    if (refresh) {
      const value = decode(refresh);
      const ieExpired = moment((value as any).exp).isAfter(moment());
      logger.debug(moment((value as any).exp * 1000).format('YYYY-MM-DD HH:mm:ss'));
      if (!ieExpired) {
        return;
      }
    }
  }, 60 * 1000);

  refresh$.subscribe((token) => {
    if (token === null) {
      accessToken.value = null;
    }
  });

  return {
    $noauthapi,
    $api,
    $get,
    $post,
    $patch,
    $put,
    $delete,
    $commonapi,
    accessToken,
    setAccessToken,
    bff,
  };
});
