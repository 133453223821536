<script setup lang="ts">
  import { zIndexMap } from '@/constants/zIndex';
  import { ConfigProvider } from 'ant-design-vue';
  import { ThemeConfig } from 'ant-design-vue/es/config-provider/context';
  import koKR from 'ant-design-vue/lib/locale/ko_KR';
  import { onMounted } from 'vue';

  const toCamelcase = (str: String) => {
    let result = '';
    let i = 0;
    while (true) {
      if (typeof str[i] === 'undefined') {
        break;
      }

      if (str[i] === '-') {
        i++;
        if (typeof str[i] !== 'undefined') {
          result += str[i].toUpperCase();
          i++;
        }
        continue;
      } else {
        result += str[i].toLowerCase();
        i++;
        continue;
      }
    }
    return result;
  };

  const mapCamelcaseKey = (record: Record<string, string>) => {
    const result: Record<string, string> = {};

    for (const k in record) {
      result[toCamelcase(k)] = record[k];
    }

    return result;
  };

  const setStyleProperties = (record: Record<string, string>) => {
    for (const k in record) {
      document.documentElement.style.setProperty(`--${k}`, record[k]);
    }
  };

  type ColorMap = {
    bg: string;
    'bg-hover': string;
    border: string;
    'border-hover': string;
    hover: string;
    '': string; // default
    active: string;
    'text-hover': string;
    text: string;
    'text-active': string;
  };

  interface GetColorSet {
    (colorMap: ColorMap, name: string, _?: number): Record<string, string>;
    (colorList: string[], name: string, defaultIdx?: number): Record<string, string>;
  }

  const getColorSet: GetColorSet = (...p) => {
    const colorSource = p[0];
    const name = p[1];

    const result: Record<string, string> = {};

    if (Array.isArray(colorSource)) {
      const defaultIdx = p[2] ?? 5;
      const colorList = colorSource;
      result[name] = colorList[defaultIdx as number];
      colorList.forEach((color, i) => {
        result[`${name}-${i + 1}`] = color;
      });
    } else {
      const colorMap = colorSource;
      for (const k in colorMap) {
        const key = k as keyof ColorMap;
        const resultKey = `color-${name}-${key}`.replace(/-$/, '');
        result[resultKey] = colorMap[key];
      }
    }

    return result;
  };

  const colorPrimaryList = ['#EEF1FF', '#C9D3FF', '#A3B5FF', '#7B93FF', '#728CFB', '#526FEC', '#3D58CA', '#2B43A8', '#1C3086', '#112064'];

  const colorOrangeList = [
    '#FFE3D6',
    '#FFCFBB',
    '#FFB18F',
    '#FF9264',
    '#FE8450',
    '#FF7133', // default
    '#F5520C',
    '#CC3D00',
    '#A33100',
    '#521900',
  ];

  const colorGreenList = [
    '#F6FFED',
    '#D9F7BE',
    '#B7EB8F',
    '#95DE64',
    '#73D13D',
    '#52C41A', // default
    '#389E0D',
    '#237804',
    '#135200',
    '#092B00',
  ];

  const colorRedList = [
    '#FFF1F0',
    '#FFCCC7',
    '#FFA39E',
    '#FF7875',
    '#FF4D4F',
    '#F5222D', // default
    '#CF1322',
    '#A8071A',
    '#820014',
    '#5C0011',
  ];
  const colorGoldList = ['#FFFBE6', '#FFF1B8', '#FFE58F', '#FFD666', '#FFC53D', '#FAAD14', '#D48806', '#AD6800', '#874D00', '#613400'];

  const colorBlueList = [
    '#E6F4FF',
    '#BAE0FF',
    '#91CAFF',
    '#69B1FF',
    '#4096FF',
    '#1677FF', // default
    '#CC3D00',
    '#003ED3',
    '#002C8C',
    '#001D66',
  ];

  const colorCyanList = [
    '#E6FFFB',
    '#B5F5EC',
    '#87e8de',
    '#5cdbd3',
    '#36cfc9',
    '#13c2c2', // default
    '#08979c',
    '#006d75',
    '#00474f',
    '#002329',
  ];

  const colorGeekBlueList = [
    '#F0F5FF',
    '#D6E4FF',
    '#ADC6FF',
    '#85a5FF',
    '#597EF7',
    '#2F54ED', // default
    '#1D39C4',
    '#10239E',
    '#061178',
    '#020852',
  ];

  const colorLimeList = [
    '#FCFFE6',
    '#F4FFB8',
    '#EAFF8F',
    '#D3F261',
    '#BAE637',
    '#A0D911', // default
    '#7CB305',
    '#5B8C00',
    '#3F6600',
    '#254000',
  ];

  const colorMagentaList = [
    '#FFF0F6',
    '#FFD6E7',
    '#FFADD2',
    '#FF85C0',
    '#F759AB',
    '#EB2F96', // default
    '#C41D7F',
    '#9E1068',
    '#780650',
    '#520339',
  ];

  const colorPurpleList = [
    '#F9F0FF',
    '#EFDBFF',
    '#D3ADF7',
    '#B37FED',
    '#9254DE',
    '#722ED1', // default
    '#531DAB',
    '#391085',
    '#22075E',
    '#120338',
  ];

  const colorVolcanoList = [
    '#FFF2E8',
    '#FFD8BF',
    '#FFBB96',
    '#FF9C6E',
    '#FF7A45',
    '#FA541C', // default
    '#D4380D',
    '#AD2102',
    '#871400',
    '#610B00',
  ];

  const colorYellowList = [
    '#FEFFE6',
    '#FFFFB8',
    '#FFFB8F',
    '#FFF566',
    '#FFEC3D',
    '#FADB14', // default
    '#D4B106',
    '#AD8B00',
    '#876800',
    '#614700',
  ];

  const getColorMap: (colorList: string[]) => ColorMap = (colorList) => ({
    bg: colorList[0],
    'bg-hover': colorList[1],
    border: colorList[2],
    'border-hover': colorList[3],
    hover: colorList[4],
    '': colorList[5], // default
    active: colorList[6],
    'text-hover': colorList[7],
    text: colorList[8],
    'text-active': colorList[9],
  });

  const colorPrimaryMap: ColorMap = getColorMap(colorPrimaryList);
  const colorSuccessMap: ColorMap = getColorMap(colorGreenList);
  const colorErrorMap: ColorMap = getColorMap(colorRedList);
  const colorWarningMap: ColorMap = getColorMap(colorGoldList);

  const customTheme: ThemeConfig = {
    token: {
      fontFamily: 'Spoqa Han Sans Neo',
      ...mapCamelcaseKey(getColorSet(colorPrimaryMap, 'primary')),
      ...mapCamelcaseKey(getColorSet(colorPrimaryMap, 'info')),
      ...mapCamelcaseKey(getColorSet(colorSuccessMap, 'success')),
      ...mapCamelcaseKey(getColorSet(colorErrorMap, 'error')),
      ...mapCamelcaseKey(getColorSet(colorWarningMap, 'warning')),
      //
      // linkDecoration: 'underline',
      // linkFocusDecoration: 'underline',
      // linkHoverDecoration: 'underline',

      // colorError: '#e57373',
      colorError: '#F5222D',
      colorWarning: '#FFA940',

      fontSizeHeading1: 46,
      fontSizeHeading2: 38,
      fontSizeHeading3: 30,
      fontSizeHeading4: 24,
      lineHeightHeading4: 1.33,
      fontSizeHeading5: 20,
      lineHeightHeading5: 1.2,

      ...getColorSet(colorOrangeList, 'orange', 5),

      // colorLabel: 'rgba(0,0,0,0.65)',
      colorTextLabel: 'rgba(0,0,0,0.65)',
      // colorDescription: 'rgba(0,0,0,0.45)',
      colorTextDescription: 'rgba(0,0,0,0.45)',
      // colorPlaceholer: 'rgba(0,0,0,0.25)',
      colorTextPlaceholder: 'rgba(0,0,0,0.25)',
      // colorDisabled: 'rgba(0,0,0,0.25)',
      // colorContainerDisabled: 'rgba(0,0,0,0.04)',
      colorText: 'rgba(0,0,0,0.88)',
      // colorTable: 'rgba(0,0,0,0.88)',
      colorBorder: 'rgba(0,0,0,0.15)',
      colorIcon: 'rgba(0,0,0,0.45)',
      //
      // colorTextHover: 'rgba(0,0,0,0.06)',
      colorBorderSecondary: 'rgba(0, 0, 0, 0.06)',

      colorBgContainerDisabled: '#F0F0F0',
    },
    components: {
      Layout: {},
      PageHeader: {
        zIndexBase: zIndexMap.HEADER,
        zIndexPopupBase: zIndexMap.HEADER,
      },
      Table: {
        zIndexPopupBase: zIndexMap.TABLE_POPUP,
        colorFillAlter: '#F5F5F5', // cell bg
        colorBorderSecondary: '#D0D0D0', // cell border
      },
      Modal: {
        zIndexPopupBase: zIndexMap.MODAL,
      },
      Select: {
        zIndexPopup: zIndexMap.DROPDOWN,
      },
      DatePicker: {
        zIndexPopupBase: zIndexMap.DROPDOWN,
      },
      Dropdown: {
        zIndexPopupBase: zIndexMap.DROPDOWN,
      },
      Tooltip: {
        zIndexPopup: zIndexMap.DROPDOWN,
      },
      Popconfirm: {
        zIndexPopup: zIndexMap.POP_CONFIRM,
      },
      Popover: {
        zIndexPopup: zIndexMap.POP_OVER,
      },
      Notification: {
        zIndexPopup: zIndexMap.NOTIFICATION,
      },
      Message: {
        zIndexPopup: zIndexMap.MESSAGE,
      },
    },
  };

  onMounted(() => {
    setStyleProperties({
      colorBrand: colorOrangeList[5],
      //
      colorBase: 'rgba(0,0,0,1)',
      colorLabel: 'rgba(0,0,0,0.65)',
      colorTextLabel: 'rgba(0,0,0,0.65)',
      colorDescription: 'rgba(0,0,0,0.45)',
      colorTextDescription: 'rgba(0,0,0,0.45)',
      colorPlaceholer: 'rgba(0,0,0,0.25)',
      colorTextPlaceholder: 'rgba(0,0,0,0.25)',
      colorDisabled: 'rgba(0,0,0,0.25)',
      colorContainerDisabled: 'rgba(0,0,0,0.04)',
      colorText: 'rgba(0,0,0,0.88)',
      colorTable: 'rgba(0,0,0,0.88)',
      colorBorder: 'rgba(0,0,0,0.15)',
      colorIcon: 'rgba(0,0,0,0.45)',
      //
      colorTextHover: 'rgba(0,0,0,0.06)',
      colorBorderSecondary: 'rgba(0, 0, 0, 0.06)',
    });

    setStyleProperties(getColorSet(colorPrimaryList, 'primary', 5));
    setStyleProperties(getColorSet(colorOrangeList, 'orange', 5));
    setStyleProperties(getColorSet(colorGreenList, 'green', 5));
    setStyleProperties(getColorSet(colorRedList, 'red', 5));
    setStyleProperties(getColorSet(colorGoldList, 'gold', 5));
    setStyleProperties(getColorSet(colorBlueList, 'blue', 5));
    setStyleProperties(getColorSet(colorCyanList, 'cyan', 5));
    setStyleProperties(getColorSet(colorGeekBlueList, 'geekBlue', 5));
    setStyleProperties(getColorSet(colorLimeList, 'lime', 5));
    setStyleProperties(getColorSet(colorMagentaList, 'margenta', 5));
    setStyleProperties(getColorSet(colorPurpleList, 'purple', 5));
    setStyleProperties(getColorSet(colorVolcanoList, 'volcano', 5));
    setStyleProperties(getColorSet(colorYellowList, 'yellow', 5));

    setStyleProperties(mapCamelcaseKey(getColorSet(colorPrimaryMap, 'primary')));
    setStyleProperties(mapCamelcaseKey(getColorSet(colorPrimaryMap, 'info')));
    setStyleProperties(mapCamelcaseKey(getColorSet(colorSuccessMap, 'success')));
    setStyleProperties(mapCamelcaseKey(getColorSet(colorErrorMap, 'error')));
    setStyleProperties(mapCamelcaseKey(getColorSet(colorWarningMap, 'warning')));
  });

  const getPopupContainer = (targetNode: HTMLElement | undefined) => {
    if (targetNode) {
      if (targetNode.parentNode) {
        let cur: HTMLElement = targetNode.parentNode as HTMLElement;

        while (cur !== null) {
          const classList = cur.classList;
          if (!classList) break;
          if (cur && classList?.contains('popup-wrapper')) break;
          if (cur === document.body) break;
          cur = cur.parentNode as HTMLElement;
        }

        if (cur !== null && cur.classList !== null && cur !== document.body) {
          const container = cur.querySelector('.popup-container') as HTMLElement;
          if (container && container instanceof HTMLElement) {
            return container;
          } else if (cur && cur instanceof HTMLElement) {
            return cur;
          }
        }
      }
    }

    return document.body as HTMLElement;
  };
</script>

<template>
  <ConfigProvider :theme="customTheme" :locale="koKR" :get-popup-container="getPopupContainer">
    <slot></slot>
  </ConfigProvider>
</template>

<style></style>
