import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia"
import moment from "moment";

import { Keys } from "@/constants/key";

import { getSection } from "@/utils/getter";

import { SchemaType } from "@/utils/types";
import { Alert } from "@/interfaces/alert";

import { useAuthPage } from "@/hooks/useAuthPage";
import { useInterval } from "@/hooks/useInterval";

import { getGroupAlertCards, isExistAlertCard } from "@/hooks/services/bff/alert-cards.service";
import { AlertCard } from "@/schema/api/bff/alert-cards.schema";
import { startEffect } from "@/hooks/services/bff/effect.service";
import { GroupTotalResponseData } from "@/schema/api/resource.schema";

// const logger = new Logger('card', {
//   isDebug: false,
// });

export const useAlertStore = defineStore(Keys.STORE.ALERT, () => {
  const { isAuthorized } = useAuthPage();
  const alerts = reactive<Record<number, Alert[]>>({});

  const curSection = computed(() => {
    return getSection(moment());
  });

  const selectedGroupId = ref(-1);
  const setSelectedGroupId = (groupId: number) => {
    selectedGroupId.value = groupId;
  }

  const exposeAlerts = computed<Alert[] | null>(() => {
    return alerts[selectedGroupId.value] ?? null;
  });

  const isExistAlert = ref(false);

  async function init(groupId: number, groupName: string): Promise<void> {
    if (!isAuthorized()) {
      return;
    }

    const groupIdStr = groupId+'';
    const results = await getGroupAlertCards(groupIdStr);
    const resultToAlert = (result: SchemaType<typeof AlertCard>): Alert => {
      return {
        name: groupName,
        control: {
          type: result.type,
          start: new Date(result.start),
          end: new Date(result.end),
          hourSection: [result.hour, result.section],
          target: result.target,
          isControl: result.is_control,
          updatedAt: result.created_at ? new Date(result.created_at) : null,
        },
        avgPower: result.avg_power,
        winning: result.winning,
        dispatchOrderTarget: result.dispatch_order_target,
        disabled: result.disabled,
      };
    }
  
    alerts[groupId] = [
      resultToAlert(results[0]), // prev,
      resultToAlert(results[1]), // next,
    ];
  }

  const setAlertDataQueue: Function[] = []; // setting 중에는 한번더 요청하지 않도록 처리
  const setAlertData = async (list: SchemaType<typeof GroupTotalResponseData>) => {
    const fn = async () => {
      const result = await isExistAlertCard();
      isExistAlert.value = result;
  
      if (result && list) {
        for (const item of list) {
          await init(item.id, item.name);
        }
      }

      setAlertDataQueue.shift();

      setAlertDataQueue[0] && setAlertDataQueue[0]();
    }

    if (setAlertDataQueue.length === 0) {
      fn();
      setAlertDataQueue.push(fn);
    } else {
      setAlertDataQueue.push(fn);
    }
  };

  const now = moment();
  const nowMinutes = now.get('minutes');

  let startEffectFn: Function | null = null;
  let closeEffectFn: Function | null = null;

  const start = (accessToken: string | null, groupList: SchemaType<typeof GroupTotalResponseData>) => {
    if (accessToken) {
      startEffectFn = () => {
        return startEffect((_evt: string) => {
          setAlertData(groupList);
        });
      }
      closeEffectFn = startEffectFn();
    } else {
      closeEffectFn && closeEffectFn();
      closeEffectFn = null;
      startEffectFn = null;
    }
  };

  const minute15 = 60 * 15;
  const nextStartMoment = moment().set('minutes', nowMinutes - (nowMinutes % 15) + 15).startOf('minutes');

  // 정각마다 지난 카드를 삭제한다.
  useInterval(() => {
    const now = moment().startOf('minutes');
    for (const groupId in alerts) {
      alerts[groupId] = alerts[groupId].filter((e) => moment(e.control.start).startOf('minutes').isSameOrAfter(now));
    }
  }, minute15, nextStartMoment);

  useInterval(() => {
    if (startEffectFn && closeEffectFn) {
      closeEffectFn();
      closeEffectFn = startEffectFn();
    }
  }, minute15, nextStartMoment.clone().add(-1, 'minutes'));

  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (startEffectFn && closeEffectFn) {
        closeEffectFn();
        closeEffectFn = startEffectFn();
      }
    } else {
      if (startEffectFn && closeEffectFn) {
        closeEffectFn();
      }
    }
  });

  return {
    curSection,
    exposeAlerts,
    isExistAlert,
    setSelectedGroupId,
    start,
  };
});