import { Routes } from './routes';

export const DATEPICKER_FORMAT = 'YYYY-MM-DD';
export const DATEPICKER_MONTH_FORMAT = 'YYYY-MM';
export const DATEPICKER_YEAR_FORMAT = 'YYYY';

export const MODE = import.meta.env.MODE;
export const API_URL = import.meta.env.VITE_API_URL;
export const SETTLEMENT_API_URL = import.meta.env.VITE_SETTLEMENT_API_URL;
export const COMMON_API_URL = import.meta.env.VITE_COMMON_API_URL;
export const BFF_API_URL = import.meta.env.VITE_BFF_API_URL;
export const WS_URL = import.meta.env.VITE_WS_URL;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const HOME_URL = Routes.RESOURCE;

export const ERROR_NOTIFICATION_DURATION = 5; // 10

// 2025-01-01 부터 적용되도록 수정
// TODO: 2025년 이후 아래 고정값으로 적용
const isAfterNewYear2025 = new Date() >= new Date('2025-01-01 00:00:00');
export const PERMIT_RATE_DANGER = isAfterNewYear2025 ? 0.08 : 0.12;
export const PERMIT_RATE_WARN = isAfterNewYear2025 ? 0.06 : 0.08;
// export const PERMIT_RATE_DANGER = 0.08;
// export const PERMIT_RATE_WARN = 0.06;

export const BFF_API_KEY = import.meta.env.VITE_BFF_API_KEY;
